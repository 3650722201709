<template>
  <!-- 需求定制订单详情 -->
  <div class="customize-detail">
    <div class="zt-block customize-detail-info">
      <el-row type="flex" class="main-header" align="middle">
        <el-col :span="4">
          <span class="title">订单信息</span>
        </el-col>
        <el-col :span="12">
          <el-row type="flex" :gutter="24">
            <el-col>
              <span>交易日期:&nbsp;&nbsp;</span>
              <span>{{
                detailInfo.createdAt | moment("YYYY/MM/DD HH:mm:ss")
              }}</span>
            </el-col>
            <el-col>
              <span>订单编号:&nbsp;&nbsp;</span>
              <span>{{ detailInfo.code }}</span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="demand-info" v-loading="demandInfo.loading">
        <el-col class="info-header-item">原需求信息</el-col>
        <el-col class="info-body-item">
          <p class="link">{{ demandInfo.title }}</p>
          <p
            v-if="
              demandInfo[demandInfo.type] && demandInfo[demandInfo.type].cat
            "
          >
            {{ demandInfo[demandInfo.type].cat.name }}
          </p>
          <div
            class="img_group"
            v-if="
              demandInfo[demandInfo.type] &&
              demandInfo[demandInfo.type].productImgs
            "
          >
            <div
              v-for="(item, index) in demandInfo[demandInfo.type].productImgs"
              :key="index"
              class="bg_img"
              :style="{
                backgroundImage: 'url(' + imgUrl + item + '!width_250px' + ')',
              }"
            ></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="zt-block">
      <el-row>
        <el-col class="info-header-item">应答信息</el-col>
        <el-col class="info-body-item">
          <el-row>
            <el-col>
              <span class="label">出价:</span>
              <span class="value highlight" v-if="detailInfo.offer.price"
                >¥{{ detailInfo.offer.price | priceFilt }}</span
              >
            </el-col>
            <el-col>
              <span class="label">最早交货:</span>
              <span class="value" v-if="detailInfo.offer.giveDate">{{
                detailInfo.offer.giveDate | moment("YYYY-MM-DD")
              }}</span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <PayType></PayType>
    <div class="zt-block">
      <el-row>
        <el-col class="info-header-item">支付金额</el-col>
        <el-col class="info-body-item">
          <el-row>
            <el-col>
              <span class="label">应付金额:</span>
              <span
                class="value stress"
                v-if="detailInfo.tradeOrder.ar !== undefined"
                >¥{{ detailInfo.tradeOrder.ar | priceFilt }}</span
              >
            </el-col>
            <el-col>
              <span class="label">已付金额:</span>
              <span
                class="value stress"
                v-if="detailInfo.tradeOrder.paidAmount !== undefined"
                >¥{{ detailInfo.tradeOrder.paidAmount | priceFilt }}</span
              >
            </el-col>
            <el-col>
              <span class="label">剩余金额:</span>
              <span
                class="value highlight"
                v-if="
                  detailInfo.tradeOrder.ar !== undefined &&
                  detailInfo.tradeOrder.paidAmount !== undefined
                "
                >¥{{
                  (detailInfo.tradeOrder.ar - detailInfo.tradeOrder.paidAmount)
                    | priceFilt
                }}</span
              >
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div v-if="detailInfo.offer">
      <ContractInfo
        :contractInfo="detailInfo.offer.offer"
        :type="demandInfo.type"
      ></ContractInfo>
    </div>
    <div class="zt-block">
      <aps :type="2" :item1="apsInfo"></aps>
    </div>
    <!-- 买方type:1 卖方type:2 , 设计作品单品页回跳过来 type:3 设计作品套系页回跳过来 type:4-->
    <!-- v-if="['2','3','4'].includes($route.query.type)" -->
    <div class="zt-block">
      <el-row  v-if="[4, 5, 6].includes(detailInfo.tradeOrder.state)">
        <el-col class="info-header-item">
          设计作品
          <span class="design">共{{ commitList.length }}条</span>
        </el-col>
        <el-col class="info-body-item">
          <el-row>
            <el-col v-if="$route.query.type !== '1'">
              <el-row type="flex">
                <el-col :span="1">描述</el-col>
                <el-input
                  style="width: 780px"
                  type="textarea"
                  placeholder="请输入"
                  v-model="replyText"
                  maxlength="150"
                  show-word-limit
                  :disabled="detailInfo.tradeOrder.state === 6"
                ></el-input>
              </el-row>
            </el-col>
            <el-col v-if="$route.query.type !== '1'">
              <el-row type="flex" align="top" class="upload-item">
                <span class="label">上传单品</span>
                <div class="works">
                  <div v-for="item in checkedSingleList" :key="item.id">
                    <span>{{ item.name }}</span>
                    <span
                      class="el-icon-circle-close close-btn"
                      @click="closeTag(item.id, 1)"
                    ></span>
                  </div>
                </div>
                <span class="primary-btn" @click="openDialog(1)">添加单品</span>
              </el-row>
            </el-col>
            <el-col v-if="$route.query.type !== '1'">
              <el-row type="flex" align="top" class="upload-item">
                <span class="label">上传套系</span>
                <div class="works">
                  <div v-for="item in checkedSeriesList" :key="item.id">
                    <span>{{ item.name }}</span>
                    <span
                      class="el-icon-circle-close close-btn"
                      @click="closeTag(item.id, 2)"
                    ></span>
                  </div>
                </div>
                <span class="primary-btn" @click="openDialog(2)">添加套系</span>
              </el-row>
            </el-col>
            <el-col
              v-if="$route.query.type !== '1'"
              style="border-bottom: 1px solid #e6e6e6; padding-bottom: 15px"
            >
              <el-button
                @click="replySubmit"
                type="primary"
                size="mini"
                :disabled="detailInfo.tradeOrder.state === 6"
                >发送</el-button
              >
            </el-col>
            <el-col>
              <h3 style="margin-top: 10px">提交记录</h3>
              <div
                class="commit-item"
                v-for="item in commitList"
                :key="item.id"
              >
                <el-row>
                  <el-col>
                    <el-row type="flex" justify="space-between">
                      <el-col>
                        <span class="time">{{
                          item.createdAt | moment("YYYY/MM/DD HH:mm:ss")
                        }}</span>
                      </el-col>
                      <el-col
                        class="danger"
                        v-if="
                          $route.query.type !== '1' &&
                          [4, 5].includes(
                            detailInfo.tradeOrder.state
                          )
                        "
                      >
                        <span class="btn" @click="del(item.id)">删除</span>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col>
                    <span class="label">描述:</span>
                    <span class="value">{{ item.desc }}</span>
                  </el-col>
                  <el-col v-if="item.single && item.single.length">
                    <el-row type="flex" align="top">
                      <div class="label">设计单品:</div>
                      <div>
                        <div v-for="single in item.single" :key="single.id">
                          <div class="primary-btn works">
                            <span>&lt;{{ single.name }}&gt;&nbsp;&nbsp;</span>
                            <span @click="viewDetail(1, single.id)"
                              >点击查看</span
                            >
                          </div>
                        </div>
                      </div>
                    </el-row>
                  </el-col>
                  <el-col v-if="item.series && item.series.length">
                    <el-row type="flex">
                      <div class="label">设计套系:</div>
                      <div>
                        <div v-for="series in item.series" :key="series.id">
                          <div class="primary-btn works">
                            <span>&lt;{{ series.name }}&gt;&nbsp;&nbsp;</span>
                            <span @click="viewDetail(2, series.id)"
                              >点击查看</span
                            >
                          </div>
                        </div>
                      </div>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-dialog
              :visible.sync="addDialog.isShow"
              :title="addDialogTitle"
              center
              :before-close="handleClose"
            >
              <el-table
                ref="designTable"
                :data="addDialog.data"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column
                  v-for="item in addDialogColumns"
                  :key="item.property"
                  :label="item.label"
                  :prop="item.property"
                ></el-table-column>
              </el-table>
              <div style="padding: 10px 0">
                <el-pagination
                  background
                  layout="prev, pager, next,total"
                  @current-change="currentChange"
                  :current-page.sync="addDialog.pageNumber"
                  :page-size="addDialog.pageCount"
                  :total="addDialog.total"
                ></el-pagination>
              </div>
              <div slot="footer">
                <el-button type="primary" @click="goAddDesign"
                  >去添加作品</el-button
                >
                <!-- <el-button type="primary" @click="addCancel">取消</el-button> -->
                <el-button type="primary" @click="addConfirm">选好了</el-button>
              </div>
            </el-dialog>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="zt-block" style="padding: 20px">
      <el-button
        @click="back()"
        style="margin-right: 50px"
        size="small"
        type="primary"
        >返 回</el-button
      >
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { IMG_URL_PRE } from "@/config";
import PayType from "@/views/want/components/PayType";
import ContractInfo from "@/views/want/components/ContractInfo";
import {
  getRequireId,
  getGaikuangOrderDetail,
  getCommitList,
  addDesign,
  delDesign,
} from "@/service/want";
import { singleList, seriesList } from "@/service/design";
import designWorkStorage from "@/utils/designWorkStorage";
import { deDuplicationArr } from "@/utils";
import { getAPSByOrderId } from "@/service/sale";
export default {
  components: {
    PayType,
    ContractInfo,
  },
  data() {
    return {
      apsInfo: null,
      checkedSingleList: [],
      checkedSeriesList: [],
      hackReset: false,
      detailInfo: {
        createdAt: "",
        code: "",
        offer: {},
        tradeOrder: {},
      },
      demandInfo: {
        loading: false,
      },
      commitList: [{ time: "2021-04-01" }],
      replyText: "",
      singleList: [],
      seriesList: [],
      addDialog: {
        isShow: false,
        type: 1,
        columns: [],
        data: [],
        pageNumber: 1,
        pageCount: 10,
        total: 0,
        single: [],
        series: [],
      },
      imgUrl: IMG_URL_PRE,
      tabList: [
        { name: "已发布" },
        { name: "已达成" },
        { name: "已关闭" },
        { name: "草稿" },
      ],
      tabIndex: 0,
      orderStateOption: [
        {
          optionCode: "1",
          optionValue: "状态1",
        },
      ],
      keywords: "",
      total: 0,
      list: [],
      plisList: [],
    };
  },
  computed: {
    addDialogTitle() {
      let title = "";
      if (this.addDialog.type === 1) {
        title = "添加单品";
      }
      if (this.addDialog.type === 2) {
        title = "添加套系";
      }
      return title;
    },
    addDialogColumns() {
      let columns = [];
      if (this.addDialog.type === 1) {
        columns = [
          { property: "name", label: "作品名称" },
          { property: "category", label: "类目" },
          { property: "style", label: "风格" },
        ];
      }
      if (this.addDialog.type === 2) {
        columns = [
          { property: "name", label: "套系名称" },
          { property: "style", label: "风格" },
        ];
      }
      return columns;
    },
  },
  async created() {
    const id = this.$route.params.id;
    this.orderId = id;
    this.demandInfo.loading = true;
    try {
      this.apsInfo = await this.getApsDetail(id);
    } catch (e) {
      this.$message.error(e.message);
      this.demandInfo.loading = false;
    }
    this.getDetailInfo(id);
    this.getSingleList();
    this.getSeriesList();
    const obj = designWorkStorage.getCommitDesignWork();
    if (["3", "4"].includes(this.$route.query.type)) {
      this.checkedSingleList = obj.single;
      this.checkedSeriesList = obj.series;
    }
    this.refreshCommitList(id);
  },
  methods: {
    getApsDetail(id) {
      return new Promise((resolve, reject) => {
        getAPSByOrderId(id).then((rst) => {
          console.log("rstgetApsDetail", rst);
          resolve([
            {
              // itemId: "eed694bbf08f4497acf7ee88039cda88",
              itemId: rst.itemId,
              due: rst.Due,
              plannedShort: rst.plannedShort,
            },
          ]);
        });
      });
    },
    addConfirm() {
      // 点击选好了存入本地缓存
      this.setStorage();
      this.addDialog.isShow = false;
      this.reset();
    },
    handleClose() {
      this.addDialog.isShow = false;
      this.reset();
    },
    reset() {
      this.addDialog.pageNumber = 1;
      this.addDialog.data = [];
      this.addDialog.total = 0;
      // setTimeout(() => {
      //   this.$refs.designTable.clearSelection();
      // }, 2000);
    },
    back() {
      if (this.$route.query.type === "1") {
        this.$router.push({ path: "/factory/demandOrder", query: { type: 1 } });
      } else {
        this.$router.push("/desgin/order/demandCustomize");
      }
    },
    async getDetailInfo(id) {
      try {
        const res = await getGaikuangOrderDetail(id);
        this.detailInfo = res;
        this.getDemandInfo(res.requirementId);
      } catch (e) {
        console.error(e.errorMsg);
      }
    },
    getDemandInfo(id) {
      getRequireId(id)
        .then((rst) => {
          this.demandInfo = rst;
          this.demandInfo.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleSelectionChange(val) {
      if (this.addDialog.type === 1) {
        this.addDialog.single = val;
      } else if (this.addDialog.type === 2) {
        this.addDialog.series = val;
      }
    },
    getList(type) {
      const params = {
        price_type: [4],
        stat: [2],
        page: this.addDialog.pageNumber,
        pagesize: this.addDialog.pageCount,
      };
      return new Promise((resolve, reject) => {
        if (type === 1) {
          singleList(params)
            .then((rst) => {
              resolve(rst);
            })
            .catch((err) => {
              reject(err);
              console.error(err);
            });
        } else if (type === 2) {
          seriesList(params)
            .then((rst) => {
              resolve(rst);
            })
            .catch((err) => {
              reject(err);
              console.error(err);
            });
        }
      });
    },
    refreshCommitList(id) {
      getCommitList(id)
        .then((rst) => {
          if (rst && rst.length > 0) {
            this.commitList = rst;
          } else {
            this.commitList = [];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addCancel() {
      this.reset();
      this.addDialog.isShow = false;
    },
    setStorage() {
      const obj = designWorkStorage.getCommitDesignWork();

      if (obj.single) {
        this.checkedSingleList = deDuplicationArr([
          ...this.addDialog.single,
          ...obj.single,
        ]);
      } else {
        this.checkedSingleList = this.addDialog.single;
      }
      if (obj.series) {
        this.checkedSeriesList = deDuplicationArr([
          ...this.addDialog.series,
          ...obj.series,
        ]);
      } else {
        this.checkedSeriesList = this.addDialog.series;
      }

      let item = {
        orderId: this.orderId,
        desc: this.replyText,
        single: this.checkedSingleList.map((item) => {
          return { id: item.id, name: item.name };
        }),
        series: this.checkedSeriesList.map((item) => {
          return { id: item.id, name: item.name };
        }),
      };
      designWorkStorage.setCommitDesignWork(item);
    },
    goToAdd() {
      if (this.addDialog.type === 1) {
        this.$router.push({
          path: "/desgin/stored/single/edit",
          query: {
            orderId: this.orderId,
          },
        });
      } else if (this.addDialog.type === 2) {
        this.$router.push({
          path: "/desgin/stored/series/edit",
          query: {
            orderId: this.orderId,
          },
        });
      }
    },
    goAddDesign() {
      if (this.addDialog.single.length || this.addDialog.series.length) {
        this.$confirm("勾选的作品将被一起添加?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.goToAdd();
          this.setStorage();
        });
      } else {
        this.goToAdd();
      }
    },
    async openDialog(type) {
      if (this.detailInfo.tradeOrder.state === 6) {
        this.$message.error("订单已完成,不能再上传作品");
        return false;
      }
      this.addDialog.isShow = true;
      this.addDialog.type = type;
      this.addDialog.loading = true;
      if (type === 1) {
        await this.getSingleList();
      } else if (type === 2) {
        await this.getSeriesList();
      }
    },
    currentChange(val) {
      this.addDialog.pageNumber = val;
      if (this.addDialog.type === 1) {
        this.getSingleList();
      } else if (this.addDialog.type === 2) {
        this.getSeriesList();
      }
    },
    async getSingleList() {
      this.singleList = await this.getList(1);
      this.addDialog.data =
        this.singleList.list &&
        this.singleList.list.map((item) => {
          return {
            id: item.id,
            name: item.name,
            category: item.cat && item.cat.name,
            style: item.style.name,
          };
        });
      this.addDialog.total = this.singleList.total;
    },
    async getSeriesList() {
      this.seriesList = await this.getList(2);
      this.addDialog.data =
        this.seriesList.list &&
        this.seriesList.list.map((item) => {
          return {
            id: item.id,
            name: item.name,
            category: item.cat && item.cat.name,
            style: item.style.name,
          };
        });
      this.addDialog.total = this.seriesList.total;
    },
    goto(link) {
      this.$router.push(link);
    },
    viewDetail(category, id) {
      // category:1 单品 2 套系
      const type = this.$route.query.type;
      if (category === 1) {
        if (type === "1") {
          this.goto(`/factory/market/single/detail/${id}?type=1`);
        } else {
          this.goto(`/desgin/stored/single/detail/${id}`);
        }
      } else if (category === 2) {
        if (type === "1") {
          this.goto(`/factory/market/series/detail/${id}?type=1`);
        } else {
          this.goto(`/desgin/stored/series/detail/${id}`);
        }
      }
    },
    replySubmit() {
      if (
        this.checkedSingleList.length === 0 &&
        this.checkedSeriesList.length === 0
      ) {
        this.$message.error("单品和套系不能都为空");
        return false;
      }
      let item = {
        orderId: this.orderId,
        desc: this.replyText,
        single: this.checkedSingleList
          ? this.checkedSingleList.map((item) => {
              return { id: item.id, name: item.name };
            })
          : [],
        series: this.checkedSeriesList
          ? this.checkedSeriesList.map((item) => {
              return { id: item.id, name: item.name };
            })
          : [],
      };
      addDesign(item)
        .then((rst) => {
          this.replyFlag = false;
          this.replyText = "";
          this.$message.success("添加成功");
          this.refreshCommitList(this.orderId);
          this.checkedSingleList = [];
          this.checkedSeriesList = [];
          designWorkStorage.removeCommitDesignWork();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    del(id) {
      if (this.detailInfo.tradeOrder.state === 6) {
        this.$message.error("交易已完成,不可删除!");
        return false;
      }
      this.$confirm("确认删除?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delDesign(id).then((rst) => {
          this.$message.success("删除成功!");
          this.refreshCommitList(this.orderId);
        });
      });
    },
    closeTag(id, type) {
      const obj = designWorkStorage.getCommitDesignWork();
      if (type === 1) {
        for (const item of obj.single) {
          if (item.id === id) {
            const index = obj.single.indexOf(item);
            obj.single.splice(index, 1);
            break;
          }
        }
        for (const item of this.checkedSingleList) {
          if (item.id === id) {
            const index = this.checkedSingleList.indexOf(item);
            this.checkedSingleList.splice(index, 1);
            break;
          }
        }
      } else if (type === 2) {
        for (const item of obj.series) {
          if (item.id === id) {
            const index = obj.series.indexOf(item);
            obj.series.splice(index, 1);
            break;
          }
        }

        for (const item of this.checkedSeriesList) {
          if (item.id === id) {
            const index = this.checkedSeriesList.indexOf(item);
            this.checkedSeriesList.splice(index, 1);
            break;
          }
        }
      }
      designWorkStorage.setCommitDesignWork(obj);
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.block {
  margin: 20px 20px;
  /* background: transparent; */
}
/*----选项卡----*/
.tab_menu {
  min-width: 680px;
  /* background: #fff; */
  padding: 10px 0;
  margin: 20px 10px;
}
.tab_menu .menu_item {
  height: 40px;
  line-height: 40px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}
.tab_menu .menu_item.cur .menu_item_button {
  color: #4d4d4d;
  background: linear-gradient(
    90deg,
    rgba(131, 98, 228, 1) 0%,
    rgba(80, 116, 238, 1) 100%
  );
  box-shadow: 0px 2px 4px 0px rgba(107, 132, 219, 1);
  border: none;
  color: #fff;
}
.menu_item_button {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  border: 1px solid rgba(179, 179, 179, 1);
  font-size: 14px;
  color: #808080;
}
.list > .list-item {
  padding: 20px;
  background: #fff;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid rgba(230, 230, 230, 1);
  font-size: 14px;
  color: #4d4d4d;
}
.list > .list-item:last-child {
  margin-bottom: 0;
}
.list > .list-item > div {
  min-width: 180px;
  min-height: 120px;
  padding: 0 30px;
  border-right: 1px solid #e6e6e6;
}
.list > .list-item > div:first-child {
  padding-left: 0;
}
.list > .list-item > div:last-child {
  border-right: none;
}
.list > .list-item .list-item-tr > div:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
  margin-top: 10px;
  word-wrap: break-word;
}
.pageCount {
  margin: 0 20px 10px 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #4d4d4d;
}
.list-item-tr >>> button {
  margin: 5px;
}
.alert-red {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #f66f6a;
  border-radius: 50%;
  margin-left: 5px;
}
</style>
<style lang="less" scoped>
.customize-detail {
  .main-header {
    height: 53px;
    background: #fafaff;
    border: 1px solid #dbe1f6;
    padding-left: 20px;

    .title {
      font-weight: 600;
      color: #3b3b3b;
    }
  }

  .demand-info {
    min-height: 200px;
  }

  .info-header-item {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #f5f5f5;

    .design {
      font-size: 12px;
      color: #808080;
    }
  }

  .info-body-item {
    padding: 10px 20px;

    .img_group > div {
      width: 120px;
      height: 120px;
      display: inline-block;
      vertical-align: top;
      margin: 5px;
      position: relative;
      left: -5px;
    }

    .upload-item {
      padding: 10px 0;

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #808080;
        margin-right: 10px;
      }

      .works {
        display: inline-block;
        // vertical-align: super;
        margin-right: 10px;
      }
    }

    .value {
      font-size: 16px;
      font-weight: 400;
      color: #4d4d4d;

      &.highlight {
        color: #f66f6a;
      }

      &.stress {
        color: #292929;
      }
    }

    .pay-type {
      width: 140px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #5074ee;
    }

    .link {
      color: #0091ff;
    }

    .primary-btn {
      // color: #409eff;
      color: #0091ff;
      font-weight: 500;
      margin: 0 10px;

      &:hover {
        cursor: pointer;
      }
    }

    .close-btn {
      color: #f66f6a;
    }

    .commit-item {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 10px;
      margin-bottom: 10px;

      .danger {
        text-align: right;
        color: #f66f6a;
        .btn {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>