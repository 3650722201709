import BaseStorage from "@/utils/storage";

let instance = null;
class DesignWorkStorage {
  static DESIGN_WORKS_KEY = '';
  constructor(uuid) {
    this.DESIGN_WORKS_KEY = `design_work_${uuid || ''}`
  }

  getCommitDesignWork() {
    return JSON.parse(window.localStorage.getItem(this.DESIGN_WORKS_KEY)) || {}
  }

  setCommitDesignWork(data) {
    window.localStorage.setItem(this.DESIGN_WORKS_KEY, JSON.stringify(data))
  }

  removeCommitDesignWork() {
    window.localStorage.removeItem(this.DESIGN_WORKS_KEY)
  }
}

export default new DesignWorkStorage()
// () => {
//   if (!instance) {
//     return new DesignWorkStorage();
//   }

//   return instance
// }